<template>
  <div class="boye-by">
    <div class="top"> </div>
    <div class="wrap"
         style="width: 100%;">
      <div class="bnContent"
           style="left: -285.5px;">
        <div class="ren bn"
             style="left: -157.653px; top: 81.8571px;"></div>
      </div>
    </div>
    <div id="main-content">
      <div class="main-warp">
        <div class="idx-p1">
          <p class="mb5 sub-btn-down"><a :href="info.gameDownload"
               target="_blank"></a></p>
          <a class="idx-down idx-down-1"
             :href="info.cloud_url"
             target="_blank">云端体验</a>
          <a class="idx-down idx-down-1"
             :href="info.gameDownload"
             target="_blank">游戏下载</a>
          <a class="idx-down idx-down-1"
             :href="info.payAddress"
             target="_blank">在线充值</a>
          <a class="idx-down idx-down-1"
             :href="info.qqGroup"
             target="_blank">申请加群</a>
        </div>
        <div class="idx-p10">
          <div class="hd">
            <h2>游戏介绍</h2>
          </div>
          <div class="content"
               style="height:340px;overflow:auto;">
            <bb v-for="(itmec, i) in info.introduce"
                :key="i">{{ itmec.title }}</bb>
          </div>
        </div>
      </div>
    </div>
    <div class="s1_wrapper">
      <div class="s1_main">
        <div class="idx-p4 clear">
          <ul>
            <li>
              <a>
                <img src="../../../../public/static/website/005/images/121.jpg"
                     width="300"
                     height="290"> <em class="img-blank"></em>
                <p> <strong>经典还原</strong> <span>正在等您到来</span>
                  <b class="more">进来就知道好不好玩</b>
                </p>
              </a>
            </li>
            <li>
              <a>
                <img src="../../../../public/static/website/005/images/125.jpg"
                     width="300"
                     height="290"> <em class="img-blank"></em>
                <p> <strong>今日新开</strong> <span>没有花里胡哨 </span> <b class="more">进来就知道好不好玩</b> </p>
              </a>
            </li>
            <li>
              <a> <img src="../../../../public/static/website/005/images/133.jpg"
                     width="300"
                     height="290"> <em class="img-blank"></em>
                <p> <strong>永不删档</strong> <span>上线送福利</span> <b class="more">进来就知道好不好玩</b> </p>
              </a>
            </li>
            <li>
              <a> <img src="../../../../public/static/website/005/images/122.jpg"
                     width="300"
                     height="290"> <em class="img-blank"></em>
                <p> <strong>独家定制</strong> <span>长久耐玩</span> <b class="more">进来就知道好不好玩</b> </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="con5">
      <ul>
        <li v-for="(itmec, i) in info.images_str"
            :key="i"><img border="0"
               :src="itmec"
               width="1040"
               height="100%"
               alt="游戏精彩截图"></li>

      </ul>
    </div>
    <div id="footer"
         class="sinSun">
      <div class="footer-content warp">
        <div class="fl footer-logo mt10"> <span><img width="180"
                 height="90"
                 src="../../../../public/static/website/005/images/bomlogo.png"
                 alt="蓝染DNF版本"></span>
        </div>
        <div class="fl footer-info">
          <p>抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
          <p>Copyright © {{ info.name }}·独家运营 All rights reserved.</p>
          <p>本游戏网站由 <a href="/"
               target="_blank">云端Tool-Admin</a> 系统免费生成提供(免责声明：本工作室不参与游戏设计与运营)</p>
        </div>
        <div class="fr footer-img">
          <a>
            <img width="26"
                 height="44"
                 src="../../../../public/static/website/005/images/footer_03.jpg">
          </a>
          <a>
            <img width="38"
                 height="44"
                 src="../../../../public/static/website/005/images/footer_05.jpg">
          </a>
          <a>
            <img width="40"
                 height="44"
                 src="../../../../public/static/website/005/images/footer_07.jpg">
          </a>
        </div>
      </div>
    </div>
    <template v-if="info.qqGroup != ''">
      <iframe style="display:none;"
              :src="info.qqGroup"></iframe>
    </template>
    <template v-if="info.music == 1">
      <audio autoplay
             loop
             id="audio"
             :src="info.musicLink"
             style="display:none"> </audio>
    </template>
  </div>
</template>

<script>

import Vue from 'vue'
export default {
  data () {
    return {
      Loading: false,
      info: {
        template: '',
        name: '',
        qqCustomer: '',
        qqGroup: '',
        registered_url: '',
        gameDownload: '',
        payAddress: '',
        introduce: [],
        music: '',
        musicLink: '',
        images_str: [],
        cloud_url: '',
      }
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
          document.title = response.data.info.name
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },

  },
};
</script>

<style src="../../../../public/static/website/005/css/style.css" scoped>
</style>
