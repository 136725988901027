<template>
  <div class="boye-by">
    <div id="Floatingbar">
      <ul>
        <li>
          <a :href="'https://wpa.qq.com/msgrd?v=3&uin=' + info.qqCustomer + '&site=qq&menu=yes'"
             title="点击交流"
             target="_blank">
            <br />游戏客服</a>
        </li>
        <li>
          <a :href="info.qqGroup"
             target="_blank"><br />快速加群</a>
        </li>
        <li>
          <a :href="info.payAddress"
             target="_blank">
            <br />在线充值</a>
        </li>
      </ul>
    </div>
    <div class="container"
         id="home">
      <div class="w1200">
        <div class="head">
          <div class="video-box">
            <div id="Version">
              <div id="Flash">
                <video src="../../../../public/static/website/002/flash/gmTool.mp4"
                       autoplay="autoplay"
                       loop="true"
                       autobuffer=""
                       muted=""></video>
              </div>
              <!-- Flash结束 -->
              <div id="Header">
                <img src="../../../../public/static/website/002/images/bg_01.jpg"
                     tppabs="images/bg_01.jpg"
                     width="1920">
                <img src="../../../../public/static/website/002/images/bg_02.jpg"
                     tppabs="images/bg_02.jpg"
                     width="1920">
                <img src="../../../../public/static/website/002/images/bg_03.jpg"
                     tppabs="images/bg_03.jpg"
                     width="1920">
                <img src="../../../../public/static/website/002/images/bg_04.jpg"
                     tppabs="images/bg_04.jpg"
                     width="1920">
                <img src="../../../../public/static/website/002/images/bg_05.jpg"
                     tppabs="images/bg_05.jpg"
                     width="1920">
              </div>
            </div>
            <video src="../../../../public/static/website/002/flash/gmTool.mp4"
                   autoplay
                   preload=""
                   loop
                   class="loop hide"></video>
            <video src="../../../../public/static/website/002/flash/gmTool.mp4"
                   tppabs="flash/wall-in.mp4"
                   autoplay
                   class="wall-in"
                   preload=""></video>
          </div>
          <p class="date1 ui-animEle4 g-animEle1"></p>
          <!-- 顶部导航 -->
          <div class="top_bar">
            <div class="top_nav ui-animEle2 g-animEle4">
              <div class="fl nav nav_left">
                <a :href="info.qqGroup"
                   title="申请进群"
                   target="_blank"
                   class="nolink on ">申请进群</a>
                <a :href="info.gameDownload"
                   title="补丁下载"
                   target="_blank"
                   class="">补丁下载</a>
                <a :href="info.payAddress"
                   title="在线充值"
                   target="_blank"
                   class="">在线充值</a>
              </div>
              <div class="fr nav nav_right">
                <a :href="info.cloud_url"
                   title="云端体验"
                   target="_blank"
                   class="nolink">云端体验</a>
                <a :href="info.gameDownload"
                   title="游戏下载"
                   target="_blank"
                   class="nolink">游戏下载</a>
                <a :href="info.payAddress"
                   title="在线充值"
                   target="_blank"
                   class="nolink">在线充值</a>
              </div>
            </div>
          </div>
          <!--下载组件 -->
        </div>
        <div class="main ui-animEle4 g-animEle8 g-animEle1 ui-animEle">
          <div class="dl_wrap ui-animEle4 g-animEle1"
               pub-name="下载"
               id="js_nie_download"
               data-download="wjsy|gw">
            <a class="dl_qrcode"
               :href="info.gameDownload"
               target="_blank"
               title="微端下载"
               pub-name="微端下载地址"></a>
            <a class="dl_pc"
               :href="info.payAddress"
               target="_blank"
               title="在线充值"
               pub-name="在线充值地址"></a>
          </div>
          <!-- 新闻 -->
          <div class="new"
               id="news">
            <div class="slide"
                 id='banner1'>
              <div class="bd">
                <div class="slide_item">
                  <a href="#"
                     target="_blank"
                     title=''>
                    <img src="../../../../public/static/website/002/images/banner2.jpg"
                         tppabs="../../../../public/static/website/002/images/banner2.jpg">
                  </a>
                </div>
              </div>
              <ul class="hd"></ul>
            </div>
            <div class="new_list"
                 id='slide1'>
              <div class="new_tabs clearfix hd">
                <a class="new_btn_tab hd_item"
                   title='最新'>最新</a>
                <a class="new_btn_tab hd_item"
                   title='新闻'>新闻</a>
                <a class="new_btn_tab hd_item"
                   title='公告'>公告</a>
                <a class="new_btn_tab hd_item"
                   title='攻略'>攻略</a>
                <a class="new_btn_tab"
                   title='更多'>更多</a>
              </div>
              <div class="new_content bd">
                <div>
                  <div class="new_first">你关心的问题？这里全都有！这是一个有诚意既稳定好服！</div>
                  <ul style="height:162px;overflow:auto;">
                    <li v-for="(itmec, i) in info.introduce"
                        :key="i">
                      <i>【话题】</i>{{ itmec.title }}
                      <span class="date"></span>
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="new_first">你关心的问题？这里全都有！这是一个有诚意既稳定好服！</div>
                  <ul style="height:162px;overflow:auto;">
                    <li v-for="(itmec, i) in info.introduce"
                        :key="i">
                      <i>【话题】</i>{{ itmec.title }}
                      <span class="date"></span>
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="new_first">你关心的问题？这里全都有！这是一个有诚意既稳定好服！</div>
                  <ul style="height:162px;overflow:auto;">
                    <li v-for="(itmec, i) in info.introduce"
                        :key="i">
                      <i>【话题】</i>{{ itmec.title }}
                      <span class="date"></span>
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="new_first">你关心的问题？这里全都有！这是一个有诚意既稳定好服！</div>
                  <ul style="height:162px;overflow:auto;">
                    <li v-for="(itmec, i) in info.introduce"
                        :key="i">
                      <i>【话题】</i>{{ itmec.title }}
                      <span class="date"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m3">
      <div class="m3_div ml30"
           v-for="(itmec, i) in info.images_str"
           :key="i">
        <div class="zb_top"></div>
        <div class="zb ">
          <div class="zb_1 ">
            <div class="role-itemst">
              <img :src="itmec">
            </div>
          </div>
        </div>
        <div class="zb_foot"></div>
      </div>
    </div>
    <div class="mask"></div>
    <!--遮罩层-->
    <div class="Layer"></div>
    <div class="footer">
      <div class="foot">
        <div class="foott">
          <div class="foota">
            <img src="../../../../public/static/website/002/images/bomlogo1.png">
          </div>
          <div class="foots">
            <div class="bshare-custom">
              <a class="bshare-more">耗资百万</a>
              <a class="bshare-weixin">重金打造</a>
              <a class="bshare-more">全国独家</a>
              <a class="bshare-more">公司运营</a>
              <a class="bshare-sinaminiblog">长久稳定</a>
              <a class="bshare-qzone">散人天堂</a>
              <a class="bshare-qzone">装备保值</a>
            </div>
            <p>提示：适合12岁以上玩家娱乐 健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
            <p>All rights reserved. 版权所有 本游戏由【{{ info.name }}】开发运营</p>
            <p> 网站框架由：<a href="/"
                 target="_0">云端Tool-Admin系统生成免费提供(免责声明：本工作室不参与游戏设计与运营)</a></p>
            <a href="/"
               target="_0"></a>
          </div>
        </div>
      </div>
    </div>
    <template v-if="info.qqGroup != ''">
      <iframe style="display:none;"
              :src="info.qqGroup"></iframe>
    </template>
    <template v-if="info.music == 1">
      <audio autoplay
             loop
             id="audio"
             :src="info.musicLink"
             style="display:none"> </audio>
    </template>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  data () {
    return {
      Loading: false,
      info: {
        template: '',
        name: '',
        qqCustomer: '',
        qqGroup: '',
        registered_url: '',
        gameDownload: '',
        payAddress: '',
        introduce: [],
        music: '',
        musicLink: '',
        images_str: [],
        cloud_url: '',
      }
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
          document.title = response.data.info.name
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    }
  },
};
</script>
<style src="../../../../public/static/website/002/css/alertBox.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/galleryV2.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/base.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/index.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/copy.css" scoped>
</style>
