<template>
  <div class="body-by">
    <div id="bigbanner">
      <img src="../../../../public/static/website/006/images/1_01.jpg"
           alt="description " /><img src="../../../../public/static/website/006/images/1_02.jpg"
           alt="description " /><img src="../../../../public/static/website/006/images/1_03.jpg"
           alt="description " /><img src="../../../../public/static/website/006/images/1_04.jpg"
           alt="description " />
    </div>
    <!-- bigbanner结束 -->
    <nav id="quicklink">
      <a :href="info.qqGroup"
         class="btn_red"
         target="_blank">
        <strong>点击加群</strong>
        <span></span>
      </a>
      <div class="qb"></div>
      <a :href="info.payAddress"
         class="btn_red"
         target="_blank">
        <strong>在线充值</strong>
        <span></span>
      </a>
      <div class="qb"></div>
      <a :href="info.cloud_url"
         class="btn_red"
         target="_blank">
        <strong>云端体验</strong>
        <span></span>
      </a>
      <div class="qb"></div>
      <a :href="info.gameDownload"
         class="btn_red"
         target="_blank">
        <strong>游戏下载</strong>
        <span></span>
      </a>
      <div class="qb"></div>
      <a :href="info.gameDownload"
         class="btn_red"
         target="_blank">
        <strong>网盘下载</strong>
        <span></span>
      </a>
    </nav>
    <!-- quicklink结束 -->

    <section id="Introductionc">
      <h3 class="PartTitle"><img src="../../../../public/static/website/006/images/txt2.png"
             alt="游戏介绍"> </h3>
      <div class="jieshao"
           style="height:434px;overflow:auto;">
        <p v-for="(itmec, i) in info.introduce"
           :key="i"><strong>★{{ itmec.title }}</strong></p>
      </div>
    </section>

    <section id="live"
             v-for="(itmec, i) in info.images_str"
             :key="i">
      <h3 class="PartTitle"> <img src="../../../../public/static/website/006/images/txt4.png"
             alt="展示"></h3>
      <div class="LiveVideo">
        <img :src="itmec">
      </div>
    </section>

    <!-- footer结束 -->
    <div id="fb"
         style="left:0">
      <span>{{ info.name }}</span>
      <ul>
        <li>解脱万缘筇杖</li>
        <li>拔云收海镜</li>
        <li><a :href="info.qqGroup">→点击加群←</a></li>
      </ul>
    </div>

    <div id="fb">
      <span>{{ info.name }}</span>
      <ul>
        <li>游戏三昧竹枝</li>
        <li>和日画山川</li>
        <li><a :href="info.qqGroup">→点击加群←</a></li>
      </ul>
      <div class="musicbox musicplay">
        <span class="music_xuanzuan rotate"></span>
        <span class="music_gif"></span>
        <template v-if="info.music == 1">
          <audio autoplay
                 loop
                 id="audio"
                 :src="info.musicLink"> </audio>
        </template>
      </div>
    </div>

    <div class="footer">
      <div class="foot">
        <div class="foott">
          <div class="foota">
            <img src="../../../../public/static/website/001/images/bomlogo1.png">
          </div>
          <div class="foots">
            <div class="bshare-custom">
              <a class="bshare-more">耗资百万</a>
              <a class="bshare-weixin">重金打造</a>
              <a class="bshare-more">全国独家</a>
              <a class="bshare-more">公司运营</a>
              <a class="bshare-sinaminiblog">长久稳定</a>
              <a class="bshare-qzone">散人天堂</a>
              <a class="bshare-qzone">装备保值</a>
            </div>
            <p>提示：适合12岁以上玩家娱乐 健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
            <p>All rights reserved. 版权所有 本游戏由【{{ info.name }}】开发运营</p>
            <p> 网站框架由：<a href="/"
                 target="_0">云端Tool-Admin系统免费生成提供(免责声明：本工作室不参与游戏设计与运营)</a></p>
            <a href="/"
               target="_0"></a>
          </div>
        </div>
      </div>
    </div>
    <template v-if="info.qqGroup != ''">
      <iframe style="display:none;"
              :src="info.qqGroup"></iframe>
    </template>

  </div>
</template>

<script>

import Vue from 'vue'
export default {
  data () {
    return {
      Loading: false,
      info: {
        template: '',
        name: '',
        qqCustomer: '',
        qqGroup: '',
        registered_url: '',
        gameDownload: '',
        payAddress: '',
        introduce: [],
        music: '',
        musicLink: '',
        images_str: [],
        cloud_url: '',
      }
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
          document.title = response.data.info.name
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },

  },
};
</script>

<style src="../../../../public/static/website/006/style/reset_css.css" scoped>
</style>
<style src="../../../../public/static/website/006/style/animate.css" scoped>
</style>
<style src="../../../../public/static/website/006/style/all.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/copy.css" scoped>
</style>