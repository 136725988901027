<template>
  <div class="boye-by"
       v-loading="Loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div id="Version">
      <div id="Header">
        <img src="../../../../public/static/website/001/123/bg_01.jpg"
             width="1920">
        <img src="../../../../public/static/website/001/123/bg_02.jpg"
             width="1920">
        <img src="../../../../public/static/website/001/123/bg_03.jpg"
             width="1920">
        <img src="../../../../public/static/website/001/123/bg_04.jpg"
             width="1920">
        <img src="../../../../public/static/website/001/123/bg_05.jpg"
             width="1920">
      </div>
      <div id="Flash">
        <video autoplay=""
               loop=""
               preload="auto"
               muted=""
               id="top-video">
          <source src="../../../../public/static/website/001/123/gmTool.mp4"
                  type="video/mp4">
        </video>
      </div>
    </div>
    <div id="main">
      <div class="head_1">
        <div class="head_3">
          <div class="head_4"></div>
          <div class="head_5"></div>
          <div class="head_6"><a>游戏介绍</a></div>
          <div class="head_10">
            <div id="tablist1">
              <div class="tablist block">
                <ul class="head_11"
                    style="height:442px;overflow:auto;">
                  <li v-for="(itmec, i) in info.introduce"
                      :key="i">
                    <span><a>★{{ itmec.title }}</a></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 加群 -->
          <div class="head_17">
            <a :href="info.qqGroup"
               target="_blank">
              <img src="../../../../public/static/website/001/images/img.gif"
                   width="294"
                   height="121">
            </a>
          </div>
          <!-- 云端地址 -->
          <div class="head_16">
            <a :href="info.cloud_url"
               target="_blank">
              <img src="../../../../public/static/website/001/images/img.gif"
                   width="294"
                   height="121">
            </a>
          </div>
          <!-- 充值 -->
          <div class="head_15">
            <a :href="info.payAddress"
               target="_blank">
              <img src="../../../../public/static/website/001/images/img.gif"
                   width="294"
                   height="121">
            </a>
          </div>
          <!-- 游戏下载 -->
          <div class="head_13">
            <a :href="info.gameDownload"
               target="_blank">
              <img src="../../../../public/static/website/001/images/img.gif"
                   width="294"
                   height="121">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="m3">
      <div class="m3_div"
           v-for="(itmec, i) in info.images_str"
           :key="i">
        <div class="zb_top"></div>
        <div class="zb ">
          <div class="zb_1 ">
            <div class="role-itemst"><img :src="itmec"
                   style="height:600px;margin-bottom:10px;"></div>
          </div>
        </div>
        <div class="zb_foot"></div>
      </div>

    </div>

    <div class="footer">
      <div class="foot">
        <div class="foott">
          <div class="foota">
            <img src="../../../../public/static/website/001/images/bomlogo1.png">
          </div>
          <div class="foots">
            <div class="bshare-custom">
              <a class="bshare-more">耗资百万</a>
              <a class="bshare-weixin">重金打造</a>
              <a class="bshare-more">全国独家</a>
              <a class="bshare-more">公司运营</a>
              <a class="bshare-sinaminiblog">长久稳定</a>
              <a class="bshare-qzone">散人天堂</a>
              <a class="bshare-qzone">装备保值</a>
            </div>
            <p>提示：适合12岁以上玩家娱乐 健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
            <p>All rights reserved. 版权所有 本游戏由【{{ info.name }}】开发运营</p>
            <p> 网站框架由：<a href="/"
                 target="_0">云端Tool-Admin系统免费生成提供(免责声明：本工作室不参与游戏设计与运营)</a></p>
            <a href="/"
               target="_0"></a>
          </div>
        </div>
      </div>
    </div>
    <template v-if="info.qqGroup != ''">
      <iframe style="display:none;"
              :src="info.qqGroup"></iframe>
    </template>
    <template v-if="info.music == 1">
      <audio autoplay
             loop
             id="audio"
             :src="info.musicLink"
             style="display:none"> </audio>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  data () {
    return {
      Loading: false,
      info: {
        template: '',
        name: '',
        qqCustomer: '',
        qqGroup: '',
        registered_url: '',
        gameDownload: '',
        payAddress: '',
        introduce: [],
        music: '',
        musicLink: '',
        images_str: [],
        cloud_url: '',
      }
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
          document.title = response.data.info.name
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    }
  },
};
</script>


<style src="../../../../public/static/website/001/css/index.css" scoped>
</style>
<style src="../../../../public/static/website/001/123/123.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/copy.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/black.css" scoped>
</style>
