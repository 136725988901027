<template>
  <div>
    <div class="g_down">
      <div class="m_downCont">
        <div class="u_hot">
          <img src="../../../../public/static/website/004/Picture/fc.gif">
          <i>
            <b>
              <font size="5"
                    color="#FF4E00">
                {{ datesj }} 火爆开区【
              </font>
              <font size="5"
                    color="#FF00FF">{{ info.name }}</font>
              <font size="5"
                    color="#FF4E00">】每日返利送不停！
              </font>
            </b>
          </i>
        </div>
        <div class="neiwen">
          <ul class="urlList">
            <!-- 注册入口开放 -->
            <template v-if="info.cloud_url != ''">
              <li>
                <a target="_blank"
                   style="background-color: rgb(179, 109, 75)"
                   :href="info.cloud_url">
                  云端体验 <i style="color: rgb(255, 187, 0)">云端体验</i> </a>
              </li>
            </template>

            <template v-if="info.registered_url != ''">
              <li>
                <a target="_blank"
                   style="background-color: rgb(179, 109, 75)"
                   :href="info.registered_url">
                  在线注册 <i style="color: rgb(255, 187, 0)">快速注册入口</i> </a>
              </li>
            </template>

            <template v-if="info.gameDownload != ''">
              <li>
                <a target="_blank"
                   style="background-color: rgb(179, 109, 75)"
                   :href="info.gameDownload">
                  游戏下载 <i style="color: rgb(255, 187, 0)">游戏下载</i> </a>
              </li>
            </template>

            <template v-if="info.payAddress != ''">
              <li>
                <a target="_blank"
                   style="background-color: rgb(179, 109, 75)"
                   :href="info.payAddress">
                  在线充值 <i style="color: rgb(255, 187, 0)">在线充值</i> </a>
              </li>
            </template>
          </ul>

        </div>
        <div class="m_downText">
          <div class="neiwen"
               style="height:276px;overflow:auto;">

            <div class="neiwen"
                 v-for="(itmec, i) in info.introduce"
                 :key="i">
              <div class="u_txtRihgt"
                   style="color: rgb(255, 123, 0);float:left;margin-left:20px;">
                <font color="#FF0000">{{ itmec.title }}</font>
              </div>
            </div>
          </div>
        </div>
        <div class="g_footer">
          <div class="m_foot1">
            <div class="top"
                 v-for="(itmec, i) in info.images_str"
                 :key="i">
              <a class="logo1">
                <p style="color: #FF0000; font-size: 24px; font-weight: bold; margin-left: 20px; margin-right: 5px; margin-top: 10px; margin-bottom: 15px">
                  【游戏展示】</p>
                <img :src="itmec">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="info.qqGroup != ''">
      <iframe style="display:none;"
              :src="info.qqGroup"></iframe>
    </template>
    <template v-if="info.music == 1">
      <audio autoplay
             loop
             id="audio"
             :src="info.musicLink"
             style="display:none"> </audio>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  data () {
    return {
      Loading: false,
      datesj: '',
      info: {
        template: '',
        name: '',
        qqCustomer: '',
        qqGroup: '',
        registered_url: '',
        gameDownload: '',
        payAddress: '',
        introduce: [],
        music: '',
        musicLink: '',
        images_str: [],
        cloud_url: '',
      }
    };
  },
  created () {
    this.getInfo()
    this.getCurrentTime()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
          document.title = response.data.info.name
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    getCurrentTime () {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();

      _this.gettime = yy + '年' + mm + '月' + dd + '日';
      this.datesj = _this.gettime
    }
  },
};
</script>

<style src="../../../../public/static/website/004/Content/css.css" scoped>
</style>
<style scoped>
.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE1 {
  color: #ff9966;
  font-weight: bold;
  font-family: "新宋体";
}

.STYLE2 {
  font-size: 14px;
  font-weight: bold;
  color: #ff00ff;
}

.STYLE10 {
  color: #999999;
}

.STYLE12 {
  color: #0000ff;
}

.STYLE14 {
  color: #ff0000;
}

.STYLE16 {
  color: #009900;
  font-size: 18px;
  font-weight: bold;
}
</style>