<template>
  <div>
    <div class="container">
      <div class="nav">
        <a href="#"
           class="t-logo"></a>
        <a href="#"
           class="t-logo2"></a>
        <div class="r-nav-c">
          <div class="al">
            <a :href="info.gameDownload"
               class="a1 on"
               target="_blank"
               title="客户端下载"></a>
          </div>
          <div class="al">
            <a :href="info.gameDownload"
               class="a2 "
               target="_blank"
               title="微端下载"></a>
          </div>
          <div class="al">
            <a :href="info.payAddress"
               class="a3 "
               target="_blank"
               title="在线充值"></a>
          </div>
          <div class="al">
            <a :href="'https://wpa.qq.com/msgrd?v=3&uin=' + info.qqCustomer + '&site=qq&menu=yes'"
               class="a4 "
               target="_blank"
               title="在线客服"></a>
          </div>
        </div>
      </div>
    </div>
    <div id="Version">
      <div id="Header"><img src="../../../../public/static/website/008/Top/images/bg_01.jpg"
             width="1920">
        <img src="../../../../public/static/website/008/Top/images/bg_02.jpg"
             width="1920">
        <img src="../../../../public/static/website/008/Top/images/bg_03.jpg"
             width="1920">
        <img src="../../../../public/static/website/008/Top/images/bg_04.jpg"
             width="1920">
        <img src="../../../../public/static/website/008/Top/images/bg_05.jpg"
             width="1920">
      </div>
      <div class="hao123">
        <img src="../../../../public/static/website/008/images/uz16bq.png">
      </div>
    </div>
    <div id="download">
      <nav id="downloada">
        <a :href="info.gameDownload"
           class="btn_downa"
           target="_blank">登录器下载 </a>
        <a :href="info.gameDownload"
           class="btn_downb"
           target="_blank">补丁下载 </a>
        <a :href="info.payAddress"
           class="btn_downc"
           target="_blank">在线充值 </a>
      </nav>
    </div>
    <div id="m1">
      <div class="center">
        <div id="sidebar">
          <div id="playbanner">
            <div class="conbox">
              <div class="Slide_">
                <a title="图片展示"
                   href="#"> <img src="../../../../public/static/website/008/images/banner1.jpg"
                       class="mainpic" /></a>
              </div>
              <div class="Slide_">
                <a title="图片展示"
                   href="#"> <img src="../../../../public/static/website/008/images/banner2.jpg"
                       class="mainpic" /></a>
              </div>
              <div class="Slide_">
                <a title="图片展示"
                   href="#"> <img src="../../../../public/static/website/008/images/banner3.jpg"
                       class="mainpic" /></a>
              </div>
            </div>
          </div>
        </div>
        <section id="news">
          <ul class="Tab">
            <li class="change"><a href="#">游戏介绍</a></li>
            <li><a href="#">游戏公告</a></li>
            <li><a href="#">游戏攻略</a></li>
            <li><a href="#">游戏新闻</a></li>
          </ul>
          <span class="clear"></span>
          <div class="Tab_nr">
            <ul class="NewsListc"
                style="height:432px;overflow:auto;">
              <li v-for="(itmec, i) in info.introduce"
                  :key="i">
                <a style="text-decoration: none;"
                   class="lx">{{ itmec.title }}</a>
              </li>
            </ul>
          </div>
          <!-- .Tab_nr结束 -->
          <div class="Tab_nr">
            <ul class="NewsListc"
                style="height:432px;overflow:auto;">
              <li v-for="(itmec, i) in info.introduce"
                  :key="i">
                <a style="text-decoration: none;"
                   class="lx">{{ itmec.title }}</a>
              </li>
            </ul>
          </div>
          <!-- .Tab_nr结束 -->
          <div class="Tab_nr">
            <ul class="NewsListc"
                style="height:432px;overflow:auto;">
              <li v-for="(itmec, i) in info.introduce"
                  :key="i">
                <a style="text-decoration: none;"
                   class="lx">{{ itmec.title }}</a>
              </li>
            </ul>
          </div>
          <!-- .Tab_nr结束 -->
          <div class="Tab_nr">
            <ul class="NewsListc"
                style="height:432px;overflow:auto;">
              <li v-for="(itmec, i) in info.introduce"
                  :key="i">
                <a style="text-decoration: none;"
                   class="lx">{{ itmec.title }}</a>
              </li>
            </ul>
          </div>
          <!-- .Tab_nr结束 -->
        </section>
      </div>
    </div>
    <div id="m2">
      <div id="m3">
        <div id="m4">
          <div class="center">
            <section id="ZhuangBei">
              <div class="center4">
                <div v-for="(itmec, i) in info.images_str"
                     :key="i">
                  <ul class="PicList3"
                      :key="i">
                    <li> <strong>装备展示 </strong>
                      <div class="photo">
                        <img :src="itmec"
                             alt="description " />
                      </div>
                    </li>
                  </ul>
                  <span class="clear"></span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="foot">
        <div class="foott">
          <div class="foota">
            <img src="../../../../public/static/website/001/images/bomlogo1.png">
          </div>
          <div class="foots">
            <div class="bshare-custom">
              <a class="bshare-more">耗资百万</a>
              <a class="bshare-weixin">重金打造</a>
              <a class="bshare-more">全国独家</a>
              <a class="bshare-more">公司运营</a>
              <a class="bshare-sinaminiblog">长久稳定</a>
              <a class="bshare-qzone">散人天堂</a>
              <a class="bshare-qzone">装备保值</a>
            </div>
            <p>提示：适合12岁以上玩家娱乐 健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
            <p>All rights reserved. 版权所有 本游戏由【{{ info.name }}】开发运营</p>
            <p> 网站框架由：<a href="/"
                 target="_0">云端Tool-Admin系统免费生成提供(免责声明：本工作室不参与游戏设计与运营)</a></p>
            <a href="/"
               target="_0"></a>
          </div>
        </div>
      </div>
    </div>
    <template v-if="info.qqGroup != ''">
      <iframe style="display:none;"
              :src="info.qqGroup"></iframe>
    </template>
    <template v-if="info.music == 1">
      <audio autoplay
             loop
             id="audio"
             :src="info.musicLink"
             style="display:none"> </audio>
    </template>
  </div>
</template>
 
<script>
import Vue from 'vue'
export default {
  data () {
    return {
      Loading: false,
      info: {
        template: '',
        name: '',
        qqCustomer: '',
        qqGroup: '',
        registered_url: '',
        gameDownload: '',
        payAddress: '',
        introduce: [],
        music: '',
        musicLink: '',
        images_str: [],
        cloud_url: '',
      }
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
          document.title = response.data.info.name
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },

  },
};
</script>

<!-- <style src="../../../../public/static/website/008/style/style.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/copy.css" scoped>
</style> -->