<template>
  <div class="by-body">
    <div id="Version">
      <div id="Header">
        <img src="../../../../public/static/website/003/123/bg.jpg"
             width="1920" />
      </div>
      <div id="Flash">
        <video autoplay
               loop
               src="../../../../public/static/website/003/123/gmTool.mp4"
               type="video/mp4"></video>
      </div>
    </div>
    <section id="GongGao">
      <h5>本服承诺</h5>
      <p>
        <a>GM不参与游戏，不给任何比例，一律平台，每天福利按时发放</a>
      </p>
    </section>
    <div id="container">
      <aside id="sidebar">
        <nav id="download">
          <!-- 游戏下载 -->
          <a :href="info.cloud_url"
             class="btn_payment"
             target="_blank"></a>
          <a :href="info.payAddress"
             class="btn_ btn_download1"
             target="_blank"></a>
          <a :href="info.gameDownload"
             class="btn_bd"
             target="_blank"></a>
          <!-- 加群 -->
          <a :href="info.qqGroup"
             class="btn_bd_sq"
             target="_blank"></a>
        </nav>
        <!-- download结束 -->
      </aside>
      <article id="neirong">
        <div id="m1">
          <section id="Introductionbg">
            <div id="Introduction">
              <ul class="Tab">
                <li class="change">
                  <a href="#">公告新闻</a>
                </li>
              </ul>
              <span class="clear"></span>
              <div class="Tab_nr">
                <div class="Scroll_box"
                     data-scroll="1"
                     data-scroll-height="290"
                     data-scroll-width="605">
                  <ul class="NewsList"
                      style="height:276px;overflow:auto;">
                    <li v-for="(itmec, i) in info.introduce"
                        :key="i">
                      <a 
                         target="_blank">{{ itmec.title }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- .Tab_nr结束 -->
            </div>
          </section>
          <article id="PlayBannerbg">
            <div id="PlayBanner">
              <div class="conbox">
                <div>
                  <a href="#">
                    <img src="../../../../public/static/website/003/images/banner1.jpg"></a>
                </div>
                <div>
                  <a href="#">
                    <img src="../../../../public/static/website/003/images/banner2.jpg"></a>
                </div>
              </div>
            </div>
          </article>
          <!-- PlayBanner结束 -->
          <span class="clear"></span>
          <!-- 清除浮动 -->
        </div>
        <!-- m1结束 -->
      </article>
    </div>

    <!-- container结束 -->
    <section class="m5">
      <div class="m5_div"
           v-for="(itmec, i) in info.images_str"
           :key="i">
        <div class="WWW_187ZG_com_zb boxstyle">
          <div class="WWW_187ZG_com_zb_1bg">
            <div class="WWW_187ZG_com_zb_1">
              <h3 class="PartTitle2">游戏展示
                <span>/ Item Show</span>
              </h3>
              <div class="role-itemst">
                <img :src="itmec">
              </div>
              <div class="WWW_187ZG_com_zb_1_d"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="footerBg">
      <div class="footerDiv">
        <a :href="info.gameDownload"
           title="登录器下载"
           target="_blank">
          <div class="bottombtn">
            <i class="wen t-wen"></i>
            <i class="wen b-wen"></i>
          </div>
        </a>
      </div>
    </div>
    <link type="text/css"
          rel="stylesheet"
          href="../../../../public/static/website/002/css/black.css"
          media="all">
    <div class="footer">
      <div class="foot">
        <div class="foott">
          <div class="foota">
            <img src="../../../../public/static/website/003/images/bomlogo1.png">
          </div>
          <div class="foots">
            <div class="bshare-custom">
              <a class="bshare-more">耗资百万</a>
              <a class="bshare-weixin">重金打造</a>
              <a class="bshare-more">全国独家</a>
              <a class="bshare-more">公司运营</a>
              <a class="bshare-sinaminiblog">长久稳定</a>
              <a class="bshare-qzone">散人天堂</a>
              <a class="bshare-qzone">装备保值</a>
            </div>
            <p>提示:适合12岁以上玩家娱乐 健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
            <p>All rights reserved. 版权所有 本游戏由【{{ info.name }}】开发运营</p>
            <p> 网站框架由：<a href="/"
                 target="_0">云端Tools-Admin系统生成免费提供(免责声明：本工作室不参与游戏设计与运营)</a></p>
            <a href="/"
               target="_0"></a>
          </div>
        </div>
      </div>
    </div>
    <template v-if="info.qqGroup != ''">
      <iframe style="display:none;"
              :src="info.qqGroup"></iframe>
    </template>
    <template v-if="info.music == 1">
      <audio autoplay
             loop
             id="audio"
             :src="info.musicLink"
             style="display:none"> </audio>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  data () {
    return {
      Loading: false,
      info: {
        template: '',
        name: '',
        qqCustomer: '',
        qqGroup: '',
        registered_url: '',
        gameDownload: '',
        payAddress: '',
        introduce: [],
        music: '',
        musicLink: '',
        images_str: [],
        cloud_url: '',
      }
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
          document.title = response.data.info.name
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    }
  },
};
</script>

<style src="../../../../public/static/website/003/style/reset_css.css" scoped>
</style>
<style src="../../../../public/static/website/003/style/all.css" scoped>
</style>
<style src="../../../../public/static/website/003/123/123.css" scoped>
</style>
