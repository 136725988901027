<template>
  <div v-loading="Loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <web001 v-if="info.module == '001'" />
    <web002 v-if="info.module == '002'" />
    <web003 v-if="info.module == '003'" />
    <web004 v-if="info.module == '004'" />
    <web005 v-if="info.module == '005'" />
    <web006 v-if="info.module == '006'" />
    <web007 v-if="info.module == '007'" />
    <web008 v-if="info.module == '008'" />
  </div>
</template>
<script>
import web001 from './components/001'
import web002 from './components/002'
import web003 from './components/003'
import web004 from './components/004'
import web005 from './components/005'
import web006 from './components/006'
import web007 from './components/007'
import web008 from './components/008'
import Vue from 'vue'
export default {
  name: "Website",
  components: {
    web001,
    web002,
    web003,
    web004,
    web005,
    web006,
    web007,
    web008,
  },
  data () {
    return {
      Loading: false,
      info: {
        module: '',
      }
    };
  },
  created () {
    document.title = ""
    this.getInfo()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info.module = response.data.info.template
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    }
  },
};
</script>
