<template>
  <div class="body-by">
    <div id="Version">
      <div id="Flash">
        <video autoplay=""
               loop=""
               preload="auto"
               muted=""
               id="top-video">
          <source src="../../../../public/static/website/007/flash/5188ps.mp4"
                  type="video/mp4">
        </video>
      </div>
      <div id="Header">
        <img src="../../../../public/static/website/007/images/bg.jpg">
      </div>
      <div id="Header_1">
        <img src="../../../../public/static/website/007/images/bg2.jpg">
      </div>
      <div id="Header_2">
        <img src="../../../../public/static/website/007/images/top.png"
             width="1920"
             height="61">
      </div>
      <div id="Header_3">
        <img src="../../../../public/static/website/007/images/m1.png"
             width="1270"
             height="852">
      </div>
      <div class="head_1">
        <div class="head_2">
          <div class="ding_3">
            <a :href="info.gameDownload"
               target="_blank">
              登录器下载
            </a>
          </div>
          <div class="ding_4">
            <a :href="info.gameDownload"
               target="_blank">
              补丁下载
            </a>
          </div>
          <div class="ding_5">
            <a :href="info.payAddress"
               target="_blank">
              游戏充值
            </a>
          </div>
          <div class="ding_6">
            <a :href="info.gameDownload"
               target="_blank">
              网盘下载
            </a>
          </div>
          <div class="ding_7">
          </div>
          <div class="ding_1">
            <marquee onmouseover=this.stop()
                     onmouseout=this.start()
                     direction=left
                     scrollAmount=10
                     scrollDelay=50>
              <a>
                独家怒火铭文1.76三职业，纯散人服，免费打顶赞，不花钱也可终级套
              </a>
            </marquee>
          </div>
          <div class="head_3">
            <a :href="info.gameDownload"
               target="_blank">
              <img src="../../../../public/static/website/007/images/img.gif"
                   alt="登录器"
                   width="200"
                   height="174">
            </a>
          </div>
          <div class="head_4">
            <a :href="info.gameDownload"
               target="_blank">
              <img src="../../../../public/static/website/007/images/img.gif"
                   alt="补丁"
                   width="200"
                   height="174">
            </a>
          </div>
          <div class="head_5">
            <a :href="info.payAddress"
               target="_blank">
              <img src="../../../../public/static/website/007/images/img.gif"
                   alt="充值"
                   width="200"
                   height="174">
            </a>
          </div>
          <div class="head_6">
            <a :href="info.gameDownload"
               target="_blank">
              <img src="../../../../public/static/website/007/images/img.gif"
                   alt="网盘"
                   width="200"
                   height="174">
            </a>
          </div>
          <div class="head_7">
            <ul class="head_28"
                style="height:450px;overflow:auto;">
              <li v-for="(itmec, i) in info.introduce"
                  :key="i">
                <span>
                  <a>
                    {{ itmec.title }}
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="head_8">
      <div class="head_9">
        <div class="head_10">
          <!--装备-->
          <div class="head_11"
               v-for="(itmec, i) in info.images_str"
               :key="i">
            <div class="head_12">
              <div class="head_13">
                <table width="100%"
                       border="0"
                       cellspacing="0"
                       cellpadding="0">
                  <tr>
                    <td height="80"
                        colspan="3">
                    </td>
                  </tr>
                  <tr>
                    <td width="160">
                    </td>
                    <td height="450"
                        align="center"
                        class="head_14">
                      <img :src="itmec"
                           style="width:1100px;height:820px">
                    </td>
                    <td width="160">
                    </td>
                  </tr>
                  <tr>
                    <td height="65"
                        colspan="3">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="foot">
        <div class="foott">
          <div class="foota">
            <img src="../../../../public/static/website/001/images/bomlogo1.png">
          </div>
          <div class="foots">
            <div class="bshare-custom">
              <a class="bshare-more">耗资百万</a>
              <a class="bshare-weixin">重金打造</a>
              <a class="bshare-more">全国独家</a>
              <a class="bshare-more">公司运营</a>
              <a class="bshare-sinaminiblog">长久稳定</a>
              <a class="bshare-qzone">散人天堂</a>
              <a class="bshare-qzone">装备保值</a>
            </div>
            <p>提示：适合12岁以上玩家娱乐 健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
            <p>All rights reserved. 版权所有 本游戏由【{{ info.name }}】开发运营</p>
            <p> 网站框架由：<a href="/"
                 target="_0">云端Tool-Admin系统免费生成提供(免责声明：本工作室不参与游戏设计与运营)</a></p>
            <a href="/"
               target="_0"></a>
          </div>
        </div>
      </div>
    </div>
    <template v-if="info.qqGroup != ''">
      <iframe style="display:none;"
              :src="info.qqGroup"></iframe>
    </template>
    <template v-if="info.music == 1">
      <audio autoplay
             loop
             id="audio"
             :src="info.musicLink"
             style="display:none"> </audio>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  data () {
    return {
      Loading: false,
      info: {
        template: '',
        name: '',
        qqCustomer: '',
        qqGroup: '',
        registered_url: '',
        gameDownload: '',
        payAddress: '',
        introduce: [],
        music: '',
        musicLink: '',
        images_str: [],
        cloud_url: '',
      }
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //读取官网配置
    getInfo () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.weburl + '/website/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
          document.title = response.data.info.name
        } else {
          this.$message({
            message: response.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },

  },
};
</script>

<!-- <style src="../../../../public/static/website/007/images/index.css" scoped>
</style>
<style src="../../../../public/static/website/002/css/copy.css" scoped>
</style> -->